import ApolloClient from "apollo-boost"
import fetch from "isomorphic-fetch"
import store from "store"

const getToken = () => {
  const token = store.get("backdrop-api-token")
  return token ? `Bearer ${token}` : ""
}

export const client = new ApolloClient({
  uri: process.env.GATSBY_GRAPHQL_ENDPOINT,
  request: operation => {
    operation.setContext({
      headers: {
        "X-Backdrop-Api-Key": process.env.GATSBY_BACKDROP_API_KEY,
        "X-Industry-Update-Token": store.get("industry_application_token"),
        authorization: getToken(),
      },
    })
  },
  fetch,
})
